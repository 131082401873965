//
// Theme style
//

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';
// Layout
@import './core/layout/base/layout';
@import 'layout/layout';

.nav-line-tabs .nav-item .nav-link.no_active {
  opacity: 0.4 !important;
}

.dataTable tbody tr td:last-child {
  text-align: right;
}
.dataTable tbody tr td:last-child :is(a, button) {
  text-wrap: nowrap;
  margin-left: auto;
}

.nav-line-tabs .nav-item .nav-link.no_active {
  border-bottom: none !important;
}
.ui.input input {
  color: #4b5675;
}
#kt_table_users thead th:last-child {
  text-align: right !important;
}

.p-treetable-toggler {
  border-radius: 50% !important;
}
.react-time-picker__wrapper {
  border: none !important;
}

.gap-21 {
  column-gap: 8rem !important;
}

.gap-22 {
  column-gap: 10.5rem !important;
}

.table thead tr th:last-child {
  text-align: left !important;
}

.p-treetable .p-treetable-tbody > tr > td {
  padding: 0 !important;
}
.vacancy_title_section {
  background-color: #0b4d99;
  padding: 10px;
  h3 {
    color: #fff;
  }
}

.active_menu {
  position: absolute !important;
  inset: initial !important;
  transform: none !important;
  top: 100% !important;
}
.select_company_text {
  cursor: pointer;
}

.text-clip-3 {
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
}

.react-datepicker__input-container {
  input {
    cursor: pointer;
    border-radius: 0.475rem;
    background: #f9f9f9;
    border: none;
    height: 30px;
    display: flex;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    outline: none;
  }
}

.apexcharts-canvas {
  width: 100% !important;
}

.apexcharts-canvas svg {
  width: 100% !important;
}

.breadcrumb {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 0.25rem;

  li {
    display: flex;
    align-items: center;
  }
}

.fv-plugins-icon-container .rmsc .dropdown-container {
  border: none;
  background-color: #f9f9f9;
}

.rmsc .dropdown-container:focus-within {
  box-shadow: none !important;
  border-color: inherit !important;
}

#kt_table_employments tbody tr td p {
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
}

.guZdik {
  width: 100% !important;
  min-width: 100% !important;
}

.vote_counts_box {
  background-color: #cecece;
  border-radius: 10px;
  overflow: hidden;

  button {
    background: transparent;
    border: none;
    outline: none;
    padding: 5px 8px;

    &.active {
      background-color: #3e97ff;
      color: #fff;
    }

    &:hover {
      background-color: #3e97ff;
      color: #fff;
    }

    &:disabled:hover {
      background: transparent;
    }
  }
}

.feedback_container {
  .feedback_btn {
    background: none;
    border: none;
    outline: none;
  }
}
