.table-card {
  .table-responsive {
    height: calc(100vh - 350px);
    thead {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: white;
    }
  }
}

// #kt_app_content_container {
//   overflow: hidden;
//   height: calc(100vh - 200px);
// }

// #kt_app_content_container > .card {
//   overflow: auto;
//   height: 100%;
// }
