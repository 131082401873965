//
// Container
//

// Desktop mode

.sturucture_container {
  background-color: #f8f9fa;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  padding: 20px 10px 10px 10px;
}
@include media-breakpoint-up(lg) {
  .app-container {
    padding-left: $app-container-padding-x !important;
    padding-right: $app-container-padding-x !important;
  }

  .app-container-fit-desktop {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  .app-container {
    max-width: none;
    padding-left: $app-container-padding-x-mobile !important;
    padding-right: $app-container-padding-x-mobile !important;
  }

  .app-container-fit-mobile {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
