.tabs {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
  gap: 10px;
  .tab {
    button {
      outline: none;
      border: none;
      background: transparent;
      cursor: pointer;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1.4px;
        background-color: #3e97ff;
        opacity: 0;
        border-radius: 8px;
        transition: all 150ms linear;
      }
      &.active {
        &::after {
          opacity: 1;
        }
      }
      &:hover {
        color: #3e97ff;
      }
    }
  }
}
