.react-datepicker {
  .react-datepicker__month-container {
    border-radius: 0.3125rem;
    border: 1px solid $text100;
    background: #fff;
    box-shadow: 0px 12px 32px 0px rgba(6, 28, 61, 0.08);
    padding: 1rem;
  }
  .react-datepicker__header {
    margin-top: 1rem;
    background-color: white;
    padding: 0;
    border-bottom: none;
  }

  .react-datepicker__navigation {
    top: 2rem;
  }
  .react-datepicker__navigation--previous {
    left: 1.5rem;
  }
  .react-datepicker__navigation--next {
    right: 1.5rem;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    @include fontSize(1rem, 300, 1.5rem, $grey900);
  }
  .react-datepicker__day-name,
  .react-datepicker__time-name {
    @include fontSize(0.75rem, 400, 0.75rem, $text100);
    width: 2.5rem;
    padding-top: 0.62rem;
    margin: 0;
    text-transform: uppercase;
  }
  .react-datepicker__day {
    @include box(2.5rem, 2.5rem);
    margin: 0;
    padding: 0.62rem 0;
    @include fontSize(0.875rem, 400, 1.25rem, $text500);
    border-radius: 0.5rem !important;
  }
  .react-datepicker__day-names {
    margin-bottom: 0;
    margin-top: 0.75rem;
  }
  .react-datepicker__day--today {
    background: $primary50;
    color: $text500;
  }
  .react-datepicker__day--selected {
    color: white;
    background-color: $primary500;
  }
  .react-datepicker__day--disabled {
    color: lightgray;
  }
}

.datePicker {
  width: 100%;
}

.custom-input {
  position: relative;
  // button {
  //   position: absolute;
  //   top: 0.75rem;
  //   left: 1.12rem;
  // }
  input {
    padding-left: 3.38rem;
  }
}

.new-custom-input {
  cursor: pointer;
  border-radius: 0.475rem;
  background: #fff;
  border: 1px solid hsl(0, 0%, 80%);
  height: 30px;
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;

  p {
    color: var(--text-100, #000);
    font-size: 12px;
    font-style: normal;
    margin: 0 !important;
    font-weight: 300;
    line-height: 24px; /* 150% */
    &:disabled {
      color: #ededed !important;
    }
  }
  button {
    height: 16px;
  }
}

.new-custom-input-err {
  cursor: pointer;
  padding: 12px 18px;
  border-radius: 5px;
  border: 1px solid var(--text-100, red);
  background: var(--white, #fff);
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    color: var(--text-100, #bbbfc3);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 150% */
  }
  button {
    height: 16px;
  }
}
