.searchbox_container {
  background-image: url('../../images/secondary-header-bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  height: 100px;
  margin-top: 75px;
  flex-shrink: 0;
  @media screen and (max-width: 992px) {
    margin-top: 0;
    height: 60px;
  }
  .searchbox_container_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
  }
}
